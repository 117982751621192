import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import { fetchPayments, downloadTransactionReceipt, approvePayment, declinePayment, InitiatePayment, retryPayment, uploadAttachment, downloadBulkTemplate, checkExistingSession, fetchSessionInfo, downloadInvalidRecipients, downloadSampleBankTemplate, reUploadInvalidCSV, EditInvalidRecipient, deleteBulkRecipient, InitiateBulkPayment, refreshPaymentStatus  } from './paymentActions'
import storage from 'redux-persist/lib/storage';

import { persistReducer } from 'redux-persist';
import { Payment } from '../../types/Transactions/Transactions';
import { BulkInValidDataType, BulkValidDataType } from '../../types/Payment/Payment';




type PaymentState = {
    loading: boolean,
    payments: Payment[]
    total: number,
    loader: boolean,
    isInitiated: boolean,
    isApproved: boolean,
    attachUrl: string,
    BulkValidData: BulkValidDataType[],
    BulkInvalidData: BulkInValidDataType[],
    session: string;
    file_name: string;
    sessionLoader: boolean;
}

const initialState: PaymentState = { 
    loading: false,
    loader: false,
    payments: [],
    total: 0,
    isInitiated: false,
    isApproved: false,
    attachUrl: "",
    BulkValidData: [],
    BulkInvalidData: [],
    session: "",
    file_name: "",
    sessionLoader: false,
} 

// Configure Redux Persist
const paymentPersistConfig = {
    key: 'payments',
    storage,
    blacklist: ["loader","BulkValidData","BulkInvalidData"]
    // Add any additional configuration options as needed
  };

  export const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers:{
        logOut: () =>{
            localStorage.setItem("token", "");
            localStorage.clear();

            return initialState
        },
        clearIsInitiated: (state) =>{
            state.isInitiated = false
        },
        clearIsApproved: (state) =>{
            state.isApproved = false
        },
        uploadDocumentFile: (state, action: PayloadAction<any>) =>{
            state.attachUrl = action.payload.document
        },
        uploadBulkCSV: (state, action:PayloadAction<any>)=>{
            state.BulkValidData = action.payload.valid_data
            state.BulkInvalidData = action.payload.invalid_data
            state.session = action.payload.session
            state.file_name = action.payload.file_name
        }
        
    },
    extraReducers: (builder) =>{
        // get all payments
        builder.addCase(fetchPayments.pending, (state) =>{
            state.loading = true
        })
        builder.addCase(fetchPayments.fulfilled, (state, action: PayloadAction<any>) =>{
            state.loading = false 
            state.payments = action.payload.data.data
            state.total = action.payload.data.count;
        })
        builder.addCase(fetchPayments.rejected, (state) =>{
            state.loading = false
        })

         // refresh payment status
         builder.addCase(refreshPaymentStatus.pending, (state) =>{
        })
        builder.addCase(refreshPaymentStatus.fulfilled, (state, action: PayloadAction<any>) =>{
            console.log(action.payload.data)
        })
        builder.addCase(refreshPaymentStatus.rejected, (state) =>{
        })

         // download transaction receipt
        builder.addCase(downloadTransactionReceipt.pending, (state) => {
            state.loader = true
        })
        builder.addCase(downloadTransactionReceipt.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(downloadTransactionReceipt.rejected, (state) => {
            state.loader = false
        })
        // download sample bulk template 
        builder.addCase(downloadBulkTemplate.pending, (state) => {
            state.loader = true
        })
        builder.addCase(downloadBulkTemplate.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(downloadBulkTemplate.rejected, (state) => {
            state.loader = false
        })

        // download sample banks list template 
        builder.addCase(downloadSampleBankTemplate.pending, (state) => {
            state.loader = true
        })
        builder.addCase(downloadSampleBankTemplate.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(downloadSampleBankTemplate.rejected, (state) => {
            state.loader = false
        })

        // download invalid recipients
        builder.addCase(downloadInvalidRecipients.pending, (state) => {
            state.loader = true
        })
        builder.addCase(downloadInvalidRecipients.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(downloadInvalidRecipients.rejected, (state) => {
            state.loader = false
        })

        // reupload invalid recipients csv
        builder.addCase(reUploadInvalidCSV.pending, (state) => {
            
        })
        builder.addCase(reUploadInvalidCSV.fulfilled, (state, action: PayloadAction<any>) => {
            state.BulkValidData = action.payload.data.valid_data
            state.BulkInvalidData = action.payload.data.invalid_data
            state.session = action.payload.data.session
            state.file_name = action.payload.data.file_name
        })
        builder.addCase(reUploadInvalidCSV.rejected, (state) => {
            
        })

        // update an invalid recipient info
        builder.addCase(EditInvalidRecipient.pending, (state) => {
            
        })
        builder.addCase(EditInvalidRecipient.fulfilled, (state, action: PayloadAction<any>) => {
            state.BulkValidData = action.payload.data.valid_data
            state.BulkInvalidData = action.payload.data.invalid_data
            state.session = action.payload.data.session
            state.file_name = action.payload.data.file_name
        })
        builder.addCase(EditInvalidRecipient.rejected, (state) => {
            
        })

        // delete a receipient
        builder.addCase(deleteBulkRecipient.pending, (state) => {
            
        })
        builder.addCase(deleteBulkRecipient.fulfilled, (state, action: PayloadAction<any>) => {
            state.BulkValidData = action.payload.data.valid_data
            state.BulkInvalidData = action.payload.data.invalid_data
            state.session = action.payload.data.session
            state.file_name = action.payload.data.file_name
        })
        builder.addCase(deleteBulkRecipient.rejected, (state) => {
            
        })

        // Initiate bulk payment
        builder.addCase(InitiateBulkPayment.pending, (state) => {
            state.loader = true
        })
        builder.addCase(InitiateBulkPayment.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(InitiateBulkPayment.rejected, (state) => {
            state.loader = false
        })

        // check for existing session of a bulk file
        builder.addCase(checkExistingSession.pending, (state) => {
            
        })
        builder.addCase(checkExistingSession.fulfilled, (state,action: PayloadAction<any>) => {
            state.session = action.payload.data.session
        })
        builder.addCase(checkExistingSession.rejected, (state) => {
            
        })

          // fetch session data
        builder.addCase(fetchSessionInfo.pending, (state) => {
            state.sessionLoader = true
            state.BulkValidData = []
            state.BulkInvalidData = []
            state.file_name = ""
        })
        builder.addCase(fetchSessionInfo.fulfilled, (state, action: PayloadAction<any>) => {
            state.sessionLoader = false
            state.BulkValidData = action.payload.data.valid_data
            state.BulkInvalidData = action.payload.data.invalid_data
            state.session = action.payload.data.session
            state.file_name = action.payload.data.file_name
        })
        builder.addCase(fetchSessionInfo.rejected, (state) => {
            state.sessionLoader = false
        })
    
        // initiate payment
          builder.addCase(InitiatePayment.pending, (state) => {
            state.loader = true
        })
        builder.addCase(InitiatePayment.fulfilled, (state) => {
            state.loader = false
            state.isInitiated = true
        })
        builder.addCase(InitiatePayment.rejected, (state) => {
            state.loader = false
        })

      
        // upload attachment
        builder.addCase(uploadAttachment.pending, (state) => {
            
        })
        builder.addCase(uploadAttachment.fulfilled, (state, action: PayloadAction<any>) => {
            state.attachUrl = action.payload.data
        })
        builder.addCase(uploadAttachment.rejected, (state) => {
            
        })
        // approve payment
        builder.addCase(approvePayment.pending, (state) => {
            state.loader = true
        })
        builder.addCase(approvePayment.fulfilled, (state) => {
            state.loader = false
            state.isApproved = true

        })
        builder.addCase(approvePayment.rejected, (state) => {
            state.loader = false
        })
        // reject payment
        builder.addCase(declinePayment.pending, (state) => {
            state.loader = true
        })
        builder.addCase(declinePayment.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(declinePayment.rejected, (state) => {
            state.loader = false
        })
        // retry payment
        builder.addCase(retryPayment.pending, (state) => {
            state.loader = true
        })
        builder.addCase(retryPayment.fulfilled, (state) => {
            state.loader = false
        })
        builder.addCase(retryPayment.rejected, (state) => {
            state.loader = false
        })
       
      
        
    },
})

const paymentReducer = persistReducer(paymentPersistConfig, paymentSlice.reducer);
export const {logOut,clearIsInitiated,clearIsApproved, uploadDocumentFile,uploadBulkCSV} = paymentSlice.actions

export default paymentReducer