import { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../redux/store";
import { Info } from "@mui/icons-material";
import upload_fileicon from "../../assets/icons/upload_file.svg";
import upload_grey from "../../assets/icons/upload_file_grey.svg";
import upload_icon from "../../assets/icons/upload.svg";
import clear_icon from "../../assets/icons/clear.svg";
import excel_icon from "../../assets/icons/excel_icon.svg";
import more_icon from "../../assets/icons/more_vert.svg";
import breaking_news_icon from "../../assets/icons/breaking_news.svg";
import toast from "react-hot-toast";
import ModalComponent from "../../components/Modals/modal";
import { useNavigate } from "react-router-dom";
import { checkExistingSession, downloadBulkTemplate, fetchSessionInfo, downloadInvalidRecipients, downloadSampleBankTemplate, reUploadInvalidCSV, EditInvalidRecipient, deleteBulkRecipient, InitiateBulkPayment } from "../../redux/Payment/paymentActions";
import { uploadBulkCSV } from "../../redux/Payment/payment";
import SearchComponent from "../../components/Search";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import warning from "../../assets/icons/warning.svg";
import { getAllBanks, getAllTags, verifyRecipientAccount } from "../../redux/Recipient/recipientActions";
import { BulkInValidDataType } from "../../types/Payment/Payment";


const BulkPaymentPage = () => {
    const [selectedType, setSelectedType] = useState("Instant");
    const { accountInfo } = useAppSelector((state) => state.account);
    const { selectedAccount } = useAppSelector((state) => state.auth);
    const { BulkValidData, BulkInvalidData,file_name, session } = useAppSelector((state) => state.payment);
    const { tags, banks } = useAppSelector((state) => state.recipient);
    const [banksState, setBanksState] = useState(banks);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [previewUrl, setPreviewUrl] = useState<string>("");
    const [imgLoader, setImgLoader] = useState(false)
    const fileRef = useRef<HTMLInputElement>(null);
    const uploadRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState("");
    // const [fileType, setFileType] = useState("")
    const [showBulkInfo, setShowBulkInfo] = useState(false)
    const [isApproveModal, setIsApproveModal] = useState(false);
    const [isInitiateLoader, setIsInitiateLoader] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isDeleteLoader, setIsDeleteLoader] = useState(false);
    const [isSessionModal, setIsSessionModal] = useState(false);
    const [templateLoader, setTemplateLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);
    const dispatch = useAppDispatch()
    const [isUploading, setIsUploading] = useState(false)
    const [isInvalidListLoader, setInvalidListLoader] = useState(false)
     const [showUploadButton, setShowUploadButton] = useState(false);
     const [searchValue, setSearchValue] = useState("");
     const [recipientAccountNameLoading, setRecipientAccountNameLoading] =
     useState(false);

     const [recipient, setRecipient] = useState<BulkInValidDataType | null>(null);
     const [accountName, setAccountName] = useState("");
     const [accountNumber, setAccountNumber] = useState(recipient ? recipient.account_number : "");
     const [amount, setAmount] = useState("");
     const [memo, setMemo] = useState("");
     const [isUpdateRecipientLoader, setUpdateRecipientLoader] = useState(false);
     const [totalSum, setTotalSum] = useState(0);
     const [deleteRowId, setDeleteRowId] = useState("");


     const handleVerifyAccountNumber = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;
      setAccountNumber(event.target.value);
  
      if (value?.length < 10 || value?.length > 10) {
        setAccountName("");
      }
    };

     const [isToggleBank, setIsToggleBank] = useState(false);
     const [isToggleTag, setIsToggleTag] = useState(false);

     // const [isCreateRecipient, setIsCreateRecipient] = useState(false);
     const [selectedBank, setSelectedBank] = useState<{
       name: string;
       id: string;
       bankCode: number | null;
     }>({
       name: "",
       id: "",
       bankCode: null,
     });

     const [selectedTags, setSelectedTags] = useState<{
      tag: string;
      id: number;
    }>({
      tag: "",
      id: 0,
    });

     const handleSelectedBank = (name: string, id: string, bankId: number) => {
      setSelectedBank({
        name: name,
        id: id,
        bankCode: bankId,
      });
      setIsToggleBank(false);
      setSearchValue("");
    };

    const handleSelectedTag = (name: string, id: number) => {
      setSelectedTags({
        tag: name,
        id: id,
      });
      setIsToggleTag(false);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      setSearchValue(value);
      if (value === "") {
        setBanksState(banks);
      } else {
        const filteredData = banks.filter((bank) =>
          bank?.name?.toLowerCase().includes(value?.toLowerCase())
        );
        setBanksState(filteredData);
      }
    };


    const getToken = () => {
      const token = localStorage.getItem("token");
      return token;
    };


    const toggleApproveModal = () =>{
        setIsApproveModal(!isApproveModal)
    }

    const toggleSessionModal = () =>{
      setIsSessionModal(!isSessionModal)
    }
    

    const toggleDropdown = (id: string) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id));
      };

    const [typeID, setTypeID] = useState(1);
  
    const recipientTypes = [
      {
        id: 1,
        title: "Valid Recipients",
      },
      {
        id: 2,
        title: "Invalid Recipients",
      },
    ];
  
    const toggleType = (id: number) => {
      setTypeID(id);
    }

    const formatToNaira = (amount:number) => {
      return new Intl.NumberFormat('en-NG', { 
        style: 'currency', 
        currency: 'NGN',
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      }).format(amount);
    };

    // const getStatusBg = (val: string) => {
    //     let result;
    //     switch (val) {
    //       case 'staff':
    //         result = "failed-badge";
    //         break;
    //       case 'agent':
    //         result = "success-badge";
    //         break;
    //       default:
    //         result = "failed-badge";
    //         break;
    //     }
    //     return result;
    //   };
  

    const handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedType(event.target.value);
      };

      const changedHandler = () => {
        if (fileRef.current?.files && fileRef.current.files.length > 0) {
            const selectedFile = fileRef.current.files[0];
            setFileName(selectedFile?.name);
            // setFileType(selectedFile.type);
            
            // checks
            if (!['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(selectedFile.type)) {
              toast.error("Please select an Excel or CSV file.",{
                style: {
                  border: "1px solid #B92043",
                  backgroundColor: "#B92043",
                  color: "#FFFFFF",
                  fontSize: 14,
                  maxWidth: 400,
                },
               });
              return;
           }

           if (selectedFile.size > 10 * 1024 * 1024) {
            toast.error("File size exceeds 10MB.",{
              style: {
                border: "1px solid #B92043",
                backgroundColor: "#B92043",
                color: "#FFFFFF",
                fontSize: 14,
                maxWidth: 400,
              },
              });
            return;
         }
        
         setShowBulkInfo(true)
         setImgLoader(true)
         setIsUploading(true)

         const xhr = new XMLHttpRequest();
         xhr.open('POST', `${process.env.REACT_APP_TITANIUM_URL}payment/bulk/upload-csv/`);
         xhr.setRequestHeader("Authorization", `Bearer ${getToken()}`);
      

         xhr.upload.addEventListener('progress', (event) => {
             const percentComplete = Math.round((event.loaded * 100.0) / event.total)
             console.log(percentComplete); // Log the progress to the console
             // You can use 'percentComplete' to update your progress loader
             setProgress(percentComplete)
         });

         xhr.onload = () => {
             if (xhr.status === 200) {
               toast.success("CSV file processed successfully", {
                 style: {
                   border: "1px solid #2B8C34",
                   backgroundColor: "#2B8C34",
                   color: "#FFFFFF",
                   fontSize: 14,
                 },
                 position: "bottom-right",
               });
                setImgLoader(false)
                setIsUploading(false)
                setProgress(0)
                setPreviewUrl(URL.createObjectURL(selectedFile));
                const response = JSON.parse(xhr.responseText);
                dispatch(uploadBulkCSV({
                  valid_data: response?.data.valid_data,
                  invalid_data: response?.data.invalid_data,
                  session: response?.data.session,
                  file_name: response?.data.file_name,
                }))
             }
             if(xhr.status === 409){
               setImgLoader(false)
               setIsUploading(false)
               setIsSessionModal(true)
               toast.success(`You have an ongoing CSV processing session!`, {
                style: {
                  border: "1px solid #2B8C34",
                  backgroundColor: "#2B8C34",
                  color: "#FFFFFF",
                  fontSize: 14,
                }
              });
             }
             if(xhr.status === 400){
              setImgLoader(false)
              setIsUploading(false)
              toast.error(`Invalid file type. Only XLSX is supported.!`, {
                style: {
                  border: "1px solid #B92043",
                  backgroundColor: "#B92043",
                  color: "#FFFFFF",
                  fontSize: 14,
                  maxWidth: 400,
                }
             });
            }
            if(xhr.status === 502){
              setImgLoader(false)
              setIsUploading(false)
              toast.error(`Oops, server currently not available, try again later!`, {
                style: {
                  border: "1px solid #B92043",
                  backgroundColor: "#B92043",
                  color: "#FFFFFF",
                  fontSize: 14,
                  maxWidth: 400,
                }
              })
            }
         };

         const formData = new FormData();
         formData.append('csv_file', selectedFile);
         xhr.send(formData);

        }
      }
      

      const handleRemoveImg = () =>{
        setPreviewUrl("")
        setShowBulkInfo(false)
      }

      type RowData = {
        date: string;
        account_number: string;
        amount: number;
        narration: string;
      };

      const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
      const [selectAll, setSelectAll] = useState(false);


      const isRowSelected = (row: RowData) =>
        selectedRows.some(
          (selectedRow) =>
            selectedRow.account_number === row.account_number &&
            selectedRow.amount === row.amount
        );

        // Handle individual checkbox change
            const handleCheckboxChange = (row:RowData) => {
              if (isRowSelected(row)) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) =>
                      selectedRow.account_number !== row.account_number ||
                      selectedRow.amount !== row.amount
                  )
                );
              } else {
                setSelectedRows([...selectedRows, row]);
              }
            };

            // Handle select all checkbox change
            const handleSelectAllChange = () => {
              if (selectAll) {
                setSelectedRows([]); // Clear all selected rows
              } else {
                setSelectedRows(BulkValidData); // Select all rows
              }
              setSelectAll(!selectAll);
            };

     
        // useEffect to update total sum whenever selectedRows changes
        useEffect(() => {
          const sum = selectedRows.reduce((acc, row) => acc + row.amount, 0);
          setTotalSum(sum);
        }, [selectedRows]);

      const handleBulkTemplate = ()=>{
          setTemplateLoader(true)
          dispatch(downloadBulkTemplate()).then((res)=>{
            if(res?.type === "downloadBulkTemplate/fulfilled"){
              setTemplateLoader(false)
            }
            else if(res?.type === "downloadBulkTemplate/rejected"){
              setTemplateLoader(false)
            }
          })
      }

      const handleSampleBankTemplate = () =>{
        setTemplateLoader(true)
        dispatch(downloadSampleBankTemplate()).then((res)=>{
          if(res?.type === "downloadSampleBankTemplate/fulfilled"){
            setTemplateLoader(false)
          }
          else if(res?.type === "downloadSampleBankTemplate/rejected"){
            setTemplateLoader(false)
          }
        })
      }

      const handleDeclineFetch = () =>{
        setIsSessionModal(false)
        setPreviewUrl("")
        setShowBulkInfo(false)
        dispatch(fetchSessionInfo({
          id: session,
          is_new: "no"
        }))
      }


      const handleInvalidRecipients = () =>{
        setInvalidListLoader(true)
        // setShowUploadButton(true);
        dispatch(downloadInvalidRecipients({
          id: session
        })).then((res)=>{
          if(res?.type === "downloadInvalidRecipients/fulfilled"){
            setShowUploadButton(true);
            setInvalidListLoader(false)
          }
          if(res?.type === "downloadInvalidRecipients/rejected"){
            setShowUploadButton(true);
            setInvalidListLoader(false)     
          }
        })
      }

      const handleReUpload = () =>{
        if (uploadRef.current?.files && uploadRef.current.files.length > 0) {
          const selectedFile = uploadRef.current.files[0];
          setFileName(selectedFile?.name);
          // setFileType(selectedFile.type);
          
          // checks
          if (!['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(selectedFile.type)) {
            toast.error("Please select an Excel or CSV file.",{
              style: {
                border: "1px solid #B92043",
                backgroundColor: "#B92043",
                color: "#FFFFFF",
                fontSize: 14,
                maxWidth: 400,
              },
             });
            return;
         }

         if (selectedFile.size > 10 * 1024 * 1024) {
          toast.error("File size exceeds 10MB.",{
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
            });
          return;
        }

        setIsUploading(true)
        setTypeID(1)
        dispatch(reUploadInvalidCSV({
          file: selectedFile,
          session_id: session
        })).then((res)=>{
          if(res?.type === "reUploadInvalidCSV/fulfilled"){
            setShowUploadButton(false);
            setIsUploading(false)
          }
          else if(res?.type === "reUploadInvalidCSV/rejected"){
            setIsUploading(false)
            toast.error(`Invalid file type. Only XLSX is supported.!`, {
              style: {
                border: "1px solid #B92043",
                backgroundColor: "#B92043",
                color: "#FFFFFF",
                fontSize: 14,
                maxWidth: 400,
              }
           });
          }
        })

       }
      }

      const editRecipientInfo = (recipient: BulkInValidDataType) =>{
        setRecipient(recipient);
        setIsOpen(!isOpen);
        setOpenDropdownId(null);
        setAccountName("");
        setSelectedBank({
          name: "",
          id: "",
          bankCode: null,
        });
        setSelectedTags({
          tag: "",
          id: 0,
        });
      }

      const deleteRecipientInfo = (id: string) =>{
        setDeleteRowId(id)
        setIsDeleteModal(!isDeleteModal)
        setOpenDropdownId(null);
      }

      const handleDeleteRecipient = () =>{
        setIsDeleteLoader(true)
        dispatch(deleteBulkRecipient({
          session_id: session,
          row_id: deleteRowId,
          list_type: typeID === 1 ? "valid": "invalid"
        })).then((res)=>{
          if(res?.type === "deleteBulkRecipient/fulfilled"){
             setIsDeleteLoader(false)
              setIsDeleteModal(false)
              setDeleteRowId("")
          }
          if(res?.type === "deleteBulkRecipient/rejected"){
            setIsDeleteLoader(false)
          }
        })
      }

      useEffect(()=>{
        if(recipient){
            setAccountNumber(recipient.account_number)
            setAmount(recipient.amount.toString())
        }
     },[recipient])

      const handleSessionFetch = () =>{
        setShowBulkInfo(true)
        setIsUploading(true)
        setIsSessionModal(false)
        dispatch(fetchSessionInfo({
          id: session,
          is_new: "yes"
        })).then((res)=>{
          if(res?.type === "fetchSessionInfo/fulfilled"){
            setIsUploading(false)
            toast.success(`You can now proceed from where you stopped!`, {
              style: {
                border: "1px solid #2B8C34",
                backgroundColor: "#2B8C34",
                color: "#FFFFFF",
                fontSize: 14,
              },
              position: "bottom-right",
            });
          }
          else if(res?.type === "fetchSessionInfo/rejected"){
            setIsUploading(false)
          }
        })
      }

      useEffect(()=>{
        if(BulkInvalidData.length > 0){
          setTypeID(2)
        }
      },[BulkInvalidData])

      useEffect(() => {
        setBanksState(banks);
        const values = {
          account_number: accountNumber,
          bank_code: selectedBank?.id,
        };
        if (accountNumber?.length === 10 && selectedBank?.name !== "") {
          setRecipientAccountNameLoading(true);
          dispatch(verifyRecipientAccount(values)).then((res) => {
            if (res?.type === "verifyRecipientAccount/fulfilled") {
              setAccountName(res?.payload?.data?.account_name);
              setRecipientAccountNameLoading(false);
            } else if (res?.type === "verifyRecipientAccount/rejected") {
              setRecipientAccountNameLoading(false);
            }
          });
        }
      }, [accountNumber, selectedBank, banks, dispatch]);

      const UpdateInvalidRecipient = () =>{
        setIsUploading(true)
        setUpdateRecipientLoader(true)
        dispatch(EditInvalidRecipient({
          session_id: session,
          row_id: recipient && recipient.id ? recipient.id: "",
          account_number: accountNumber,
          bank: selectedBank?.id,
          amount: parseFloat(amount),
          narration: memo,
          recipient_tag: selectedTags.tag
        })).then((res)=>{
          if(res?.type === "EditInvalidRecipient/fulfilled"){
             setIsOpen(false)
             setIsUploading(false)
             setUpdateRecipientLoader(false)
             setAccountName("");
             setMemo("")
            setSelectedBank({
              name: "",
              id: "",
              bankCode: null,
            });
            setSelectedTags({
              tag: "",
              id: 0,
            });
          }
          if(res?.type === "EditInvalidRecipient/rejected"){
            setIsOpen(false)
            setIsUploading(false)
            setUpdateRecipientLoader(false)
            setSelectedBank({
              name: "",
              id: "",
              bankCode: null,
            });
            setSelectedTags({
              tag: "",
              id: 0,
            });
          }
        })
      }

      const handleBulkPayment = () =>{
        setIsInitiateLoader(true)
        dispatch(InitiateBulkPayment({
          session_id: session,
          account_id: selectedAccount?.account_id,
          valid_list: selectedRows
        })).then((res)=>{
          if(res?.type === "InitiateBulkPayment/fulfilled"){
            setIsInitiateLoader(false)
            navigate(`/payment`);
          }
          if(res?.type === "InitiateBulkPayment/rejected"){
            setIsInitiateLoader(false)
          }
        })
      }

      useEffect(()=>{
        dispatch(getAllBanks());
        dispatch(getAllTags());
        dispatch(checkExistingSession()).then((res)=>{
          if(res?.type === "checkExistingSession/fulfilled"){
            setIsSessionModal(true)
          }
          else if(res?.type === "checkExistingSession/rejected"){
            setIsSessionModal(false)
          }
        })
      },[dispatch])




    return ( 
        <>
     {/* Confirmation Modal */}
      <ModalComponent
        title="Make Payment"
        subtitle=""
        isOpen={isApproveModal}
        onClose={toggleApproveModal}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
          Are you sure you want to proceed with payment?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsApproveModal(false)}
              >
                Cancel
              </p>
              <button
              disabled={isInitiateLoader}
              onClick={handleBulkPayment}
                className="outline-none text-white rounded-lg bg-[#2B8C34]  hover:bg-[#24752B] disabled:bg-[#ABB6AC] text-sm font-medium leading-5 px-[20px] py-[10px]"
              >
                Send Payment
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Session Modal */}
      <ModalComponent
        title="Continue from last session?"
        subtitle=""
        isOpen={isSessionModal}
        onClose={toggleSessionModal}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
          Do you want to continue from where you stopped in your last session?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={handleDeclineFetch}
              >
                No
              </p>
              <button
              onClick={handleSessionFetch}
                className="outline-none text-white rounded-lg bg-[#2B8C34]  hover:bg-[#24752B] disabled:bg-[#ABB6AC] text-sm font-medium leading-5 px-[20px] py-[10px]"
              >
                Yes, Continue
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Edit recipient modal */}
      <ModalComponent
        title="Edit Recipient"
        subtitle=""
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div>

         <div className="mt-3">
            <label
              htmlFor="accountNumber"
              className="block mb-2 text-[#344335] text-sm font-normal"
            >
              Recipient Account Number
            </label>
            <input 
              type="number"
              name="accountNumber"
              value={accountNumber}
              onChange={(e) => handleVerifyAccountNumber(e)}
              placeholder="Enter an account number"
              className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
            />
               {recipientAccountNameLoading ? (
                  <div className="ml-1">
                    {" "}
                    <ThreeDots
                      visible={true}
                      height="25"
                      width="25"
                      color="#2B8C34"
                      radius="9"
                    />
                  </div>
                ) : (
                  <p className="text-sm font-medium leading-4 text-[#5C715E] mt-1 capitalize">
                    {accountName?.toLowerCase()}
                  </p>
                )}
          </div>

           {/* BANK */}
        <div className="mt-4">
          <label
            htmlFor="bank"
            className="block mb-2 text-[#344335] text-sm font-normal"
          >
            Bank
          </label>
          <div className="relative">
            <div
              onClick={() => setIsToggleBank(!isToggleBank)}
              className={`flex cursor-pointer justify-between items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
              style={
                isToggleBank ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
              }
            >
              <p
                className={`${
                  selectedBank?.name ? "text-[#344335]" : "text-[#96A397]"
                }`}
              >
                {selectedBank?.name || "Select a bank"}
              </p>
              {isToggleBank ? (
                <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
              ) : (
                <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
              )}
            </div>

            {/*  SELECT OPTIONS */}
            {isToggleBank && (
              <div
                className=" rounded-[18px] py-2 bg-white w-full mt-2 px-2 max-h-[300px] overflow-scroll "
                style={{
                  boxShadow:
                    "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className="w-full mb-2">
                  <SearchComponent
                    placeholder="Search"
                    searchValue={searchValue}
                    handleChange={handleSearchChange}
                  />
                </div>

                {banksState?.map((bank, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() =>
                        handleSelectedBank(bank?.name, bank?.code, bank?.id)
                      }
                      className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                        selectedBank?.name === bank?.name
                          ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                          : "text-[#344335] bg-white"
                      }`}
                    >
                      <p className=" leading-5 text-sm font-medium">
                        {bank?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

          {/* TAG */}
          <div className="mt-4">
          <label
            htmlFor="bank"
            className="block mb-2 text-[#344335] text-sm font-normal"
          >
            Tag
          </label>
          <div className="relative">
            <div
              className={`flex cursor-pointer  items-center w-full rounded-lg border border-[#96A397] bg-white py-3 px-4 text-[#96A397] text-sm`}
              style={
                isToggleTag ? { boxShadow: "0px 0px 0px 2px #D5E8D6" } : {}
              }
              onClick={() => setIsToggleTag(!isToggleTag)}
            >
              <div className="flex flex-wrap  gap-2 items-center w-[95%]">
                {selectedTags?.tag ? (
                  <>
                    <div className="rounded-[4px] capitalize text-sm bg-[#DBFFDF] px-1 py-[2px] text-[#2B8C34] flex items-center gap-2">
                      <p> {selectedTags?.tag}</p>
                    </div>
                  </>
                ) : (
                  <p> Select a tag</p>
                )}
              </div>

              <div className=" w-[5%]">
                {isToggleTag ? (
                  <ExpandLess style={{ fontSize: "20px", color: "#344335" }} />
                ) : (
                  <ExpandMore style={{ fontSize: "20px", color: "#344335" }} />
                )}
              </div>
            </div>

            {/*  SELECT OPTIONS */}
            {isToggleTag && (
              <div
                className="max-h-[300px] py-2 overflow-scroll rounded-[18px] bg-white w-full mt-2 px-2"
                style={{
                  boxShadow:
                    "0px 16px 32px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                {tags?.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleSelectedTag(tag?.tag, tag?.id)}
                      className={`px-4 py-3  text-sm font-normal cursor-pointer hover:bg-[#EDF7EE] hover:rounded-[8px] mb-1 ${
                        selectedTags?.tag === tag?.tag
                          ? "bg-[#EDF7EE] border-[1px] border-[#2B8C34] rounded-[8px] text-[#24752B]"
                          : "text-[#344335] bg-white"
                      }`}
                    >
                      <p className="capitalize leading-5 text-sm font-medium">
                        {tag?.tag}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
            <label
              htmlFor="amount"
              className="block mb-2 text-[#344335] text-sm font-normal"
            >
              Amount
            </label>
            <input 
              type="text"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter an amount"
              className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
            />
       </div>

         <div className="mt-5">
              <label
                htmlFor="memo"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Payment Memo
              </label>
              <textarea
                name="memo"
                rows={6}
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder="Enter a payment memo"
                className="appearance-none resize-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
              />
            </div>

          <div className="mt-5">
          <button
         disabled={
          accountName === "" ||
          selectedTags?.tag === "" ||
            amount === ""
            || isUpdateRecipientLoader
        }
          onClick={UpdateInvalidRecipient}
              className="bg-primary 
                disabled:bg-[#ABB6AC] w-full
              rounded-lg px-6 py-4 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
            >
              Save changes
            </button>
          </div>

        </div>
        </ModalComponent>

        {/* Delete a recipient modal */}
        <ModalComponent
        title="Delete Recipient"
        subtitle=""
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
      >
        <div>
          <p className="mt-[10px] text-sm font-mediumn leading-5 text-[#5C715E] mb-8">
          Are you sure you want to delete this recipient?
          </p>

          <div className="flex justify-end">
            <div className="flex gap-[40px] items-center cursor-pointer">
              <p
                className=" text-sm font-mediumn leading-5 text-[#5C715E]"
                onClick={() => setIsDeleteModal(false)}
              >
                No
              </p>
              <button
              disabled={isDeleteLoader}
              onClick={handleDeleteRecipient}
                className="outline-none text-white rounded-lg bg-[#2B8C34]  hover:bg-[#24752B] disabled:bg-[#ABB6AC] text-sm font-medium leading-5 px-[20px] py-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>



        <div className="mt-4 bg-white rounded-[8px] py-6 px-6">
            <div className="grid grid-cols-3 gap-4">
                {/* first section */}
                <div>
                <div>
                <label
                    htmlFor="attachment"
                    className="flex items-center gap-2 mb-2 text-[#344335] text-sm font-normal"
                >
                    Attachment (Optional)
                </label>

                {!imgLoader  && previewUrl &&
                   <div className='relative max-w-full w-full border border-[#A6A08C] flex flex-col justify-center items-center rounded-[8px] h-[160px]'>
                    <div className="text-center">
                    <img src={excel_icon} alt="file icon" className='' />
                    </div>
                    <div>
                    <p className="text-[#344335] text-sm font-medium mt-1">{fileName}</p>
                    </div>
                        <div onClick={() => handleRemoveImg()} className="absolute right-0 -top-2 cursor-pointer bg-[#f7f7f7] border border-[#DDE2DE] w-[25px] h-[25px] rounded-full flex justify-center items-center">
                          <img src={clear_icon} alt="clear icon" />
                        </div>
                    </div>
                    }
                  

                    {!imgLoader && !previewUrl &&
                        <label className="bg-white border border-[#A6A08C] rounded-[8px] cursor-pointer flex flex-col justify-center items-center min-h-[160px]">
                        <input 
                                ref={fileRef}
                                type="file"
                                accept=".csv, text/csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
                                onChange={() => changedHandler()}
                                className='hidden'/>
                            <div>
                            <img src={upload_icon} alt="upload icon" />
                        </div>
                        <div>
                            <p className="text-[#2B8C34] text-sm font-semibold mt-2">
                            Choose a file to Upload
                            </p>
                            <p className="text-[#344335] text-center text-sm mt-1">
                            Upload a .xlsx file
                            </p>
                           
                        </div>
                        </label>
                    }

                    {imgLoader &&
                        <div className="bg-white border border-[#A6A08C] rounded-[8px] px-6 flex flex-col justify-center items-center min-h-[160px]">
                            <div>
                            <img src={upload_fileicon} alt="upload icon" />
                            </div>
                            <div>
                            <p className="text-[#344335] text-sm font-medium mt-2">
                                Uploading Attachment...
                            </p>
                            </div>
                            <div className="bg-[#ECEFEC] rounded-[8px] h-[10px] mt-3 w-full">
                            <div className={`bg-[#2B8C34] rounded-[8px] h-[10px]`} style={{width: `${progress}%`}}></div>
                            </div>
                        </div>
                    }
                </div>
                <div className="mt-2">
                  <p className="text-[#16461A] text-sm font-medium"> Download the <button disabled={templateLoader} onClick={handleBulkTemplate} className="text-[#2B8C34] disabled:opacity-[0.8] font-medium text-sm">sample .xlsx file</button> for guide.</p>
                  <p className="text-[#16461A] text-sm font-medium mt-1"> For a list of banks and recipient tags <button disabled={templateLoader} onClick={handleSampleBankTemplate} className="text-[#2B8C34] disabled:opacity-[0.8] font-medium text-sm"> Download file</button></p>
                  <p className="text-[#16461A] text-sm font-medium mt-1">The order of the columns should not be tampered with!</p>
                </div>
               
                </div>

             

                {/* second section */}
                <div>

                <div>
                    <label
                        htmlFor="Subaccount"
                        className="block mb-2 text-[#344335] text-sm font-normal"
                    >
                        Subaccount
                    </label>
                    <input
                        type="text"
                        name="Subaccount"
                        placeholder="Subaccount"
                        value={selectedAccount ? selectedAccount.name : ""}
                        disabled
                        className="appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                    />
                    {selectedAccount?.type !== "main_account" && (
                        <div className="flex items-center gap-1 mt-1">
                        <Info style={{ fontSize: "16px", color: "#344335" }} />
                        <small className="text-[#344335] font-medium text-xs">
                            Account balance:{" "}
                            {`₦${
                            accountInfo
                                ? accountInfo?.account_balance?.toLocaleString()
                                : 0
                            }`}
                        </small>
                        </div>
                      )}
                  </div>

                  <div className='mt-4'>
                      <label
                          htmlFor="payableAmount"
                          className="block mb-2 text-[#344335] text-sm font-normal"
                      >
                          Total Payable Amount
                      </label>
                      <input
                          type="text"
                          name="payableAmount"
                          value={formatToNaira(totalSum)}
                          placeholder="Enter an amount"
                          disabled
                          className={`appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm rounded-lg border bg-white py-3 px-4 ${
                            totalSum > (parseFloat(accountInfo?.account_balance) || 0)
                                ? 'border-[#B92043] focus:border-[#B92043]'
                                : 'border-[#96A397] focus:border-[#2B8C34]'
                        }`}
                      />

                        {totalSum > (parseFloat(accountInfo?.account_balance) || 0) && (
                              <div className="flex mt-2">
                                  <img src={warning} className="" alt="warning" />
                                  <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                                     You don't have sufficent funds to process this payment!
                                  </small>
                              </div>
                            )}
                        
                    </div>

                  </div>
            

                {/* third section */}
                <div>
              <label
                htmlFor="type"
                className="block mb-2 text-[#344335] text-sm font-normal"
              >
                Payment Type
              </label>

              <div className="mt-4 ml-4">
                <div className="flex items-center">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value="Instant"
                    name="default-radio"
                    checked={selectedType === "Instant"}
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34] cursor-pointer bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-1"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Instant"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    } text-[#344335]`}
                  >
                    Instant Payment
                  </label>
                </div>

                <div className="flex items-center mt-4">
                  <input
                    id="default-radio-2"
                    type="radio"
                    disabled
                    checked={selectedType === "Recurrring"}
                    value="Recurrring"
                    name="default-radio"
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34]  bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-2"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Recurrring"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    }`}
                  >
                    Recurring Payment
                  </label>
                  <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                    <p>Coming soon</p>
                  </div>
                </div>

                <div className="flex items-center mt-4">
                  <input
                    id="default-radio-3"
                    type="radio"
                    value="Scheduled"
                    disabled
                    name="default-radio"
                    checked={selectedType === "Scheduled"}
                    onChange={handleType}
                    className="w-4 h-4 text-[#2B8C34] bg-gray-100 border-gray-300 focus:ring-[#2B8C34] focus:ring-0 "
                  />
                  <label
                    htmlFor="default-radio-3"
                    className={`ml-2 text-sm font-medium ${
                      selectedType === "Scheduled"
                        ? "text-[#344335]"
                        : "text-[#ABB6AC]"
                    }`}
                  >
                    Scheduled Payment
                  </label>
                  <div className="ml-2 rounded-[4px] text-xs bg-[#c9f7fc] px-1 py-[3px] text-[#0066af] flex items-center gap-2">
                    <p>Coming soon</p>
                  </div>
                </div>
              </div>
                  </div>

            </div>

             <div className="flex justify-end mt-6">
                <div className="flex gap-3">
                    {/* <button className="py-3 flex items-center justify-center px-5 bg-transparent rounded-[8px] text-[#5C715E] text-sm font-medium  transition-all">
                        Cancel
                    </button> */}
                      <button
                      onClick={toggleApproveModal}
                      disabled={
                        selectedRows.length === 0 ||
                       totalSum > (parseFloat(accountInfo?.account_balance)) 
                      }
                         className="py-3 flex items-center disabled:bg-[#ABB6AC] hover:bg-[#24752B] justify-center px-5 bg-[#2B8C34] rounded-[8px] text-[#fff] text-sm font-medium  transition-all"
                    >
                        Make Payment
                    </button>
                </div>
            </div>
        </div>

       {showBulkInfo &&
        <div className="mt-5 bg-white rounded-[8px] py-6 px-6">
            <div className="flex justify-between items-center">
                <div>
                    <p className="text-primary font-semibold text-base leading-5">
                    {file_name ? file_name : "Selected File"}
                    </p>
                </div>
                {
               typeID === 2 && BulkInvalidData.length > 0 &&
                  <div className="flex gap-4 items-center">
                     {!showUploadButton && (
                    <button
                      onClick={handleInvalidRecipients}
                      disabled={isInvalidListLoader}
                        className="py-3 flex items-center disabled:bg-opacity-[0.5] disabled:text-opacity-[0.5] hover:bg-opacity-[0.5] justify-center px-5 bg-[#EDF7EE] rounded-[8px] text-[#2B8C34] text-sm font-medium  transition-all"
                    >
                       Download Invalid Recipients
                    </button>
                     )}

                {showUploadButton &&
               (<label className="py-3 flex gap-2 cursor-pointer items-center hover:bg-opacity-[0.8] justify-center px-5 bg-[#ECEFEC] rounded-[8px] text-[#96A397] text-sm font-medium  transition-all">
                      <input 
                            ref={uploadRef}
                            type="file"
                            accept=".csv, text/csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
                            onChange={() => handleReUpload()}
                            className='hidden'/>
                        <p> Upload Recipient</p>   
                        <img src={upload_grey} alt="upload icon" />
                    </label>
                  )}
                    
                  
                </div>
                }
            </div>
                <ul className={`flex ${typeID === 1 ? 'mt-4': 'mt-2'} gap-5 items-center`}>
                {recipientTypes.map((item, index) => (
                  <li
                    key={item.id}
                    onClick={() => toggleType(item.id)}
                    className={
                      typeID === item.id
                        ? `flex gap-1 items-center border-b-2 ${typeID === 2 ? 'border-[#B92020]' : 'border-primary'}  pb-1 cursor-pointer relative`
                        : "flex gap-1 items-center pb-1 cursor-pointer relative"
                    }
                  >
                    <p
                      className={
                        typeID === item.id
                          ? ` ${typeID === 2 ? 'text-[#B92020]' : 'text-primary'} text-sm font-semibold`
                          : "text-[#96A397] text-sm font-semibold"
                      }
                    >
                      {item.title}
                    </p>
                   {index === 0 && typeID === 1 &&
                     <div className="bg-[#ECEEEC] text-[#96A397]  w-[18px] h-[18px] text-[10px] font-semibold flex justify-center items-center rounded-[4px]">
                      {BulkValidData.length}
                    </div>
                      }
                    {index === 1 && typeID === 2 &&
                      <div className="bg-[#FDDED4] text-[#B92020]  w-[18px] h-[18px] text-[10px] font-semibold flex justify-center items-center rounded-[4px]">
                      {BulkInvalidData.length}
                    </div>
                  }
                  </li>
                ))}
                </ul>

              {
                isUploading
                ?
                (
                <div>
                   <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                    <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                  </div>
                </div>
                )
                :
                (
                  <>
                  
                 {
                   typeID === 1 &&
                    BulkValidData?.length === 0 &&
                  <div className="w-full flex flex-col items-center justify-center min-h-[300px]">
                      <div className='text-center'>
                        <div className="flex justify-center">
                         <img src={breaking_news_icon} alt="breaking_news_icon" />
                        </div>
                        <p className='text-sm text-center max-w-xs font-medium text-[#000000] mt-2'>
                       You don’t have any lists yet. When 
                       you create one, it will show up here.
                        </p>
                    </div>                           
                  </div>
                  }
                  
                 {
                  typeID === 1 &&
                    BulkValidData?.length > 0  &&
                  <table className="w-full text-left relative mt-4">
                      <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                          <tr>
                          <th scope="col" className="px-4 py-4 font-semibold ">
                                    <input 
                                    type="checkbox"
                                     className="accent-primary"
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                     />
                            </th> 
                          <th
                              scope="col"
                              className="px-4 py-4 font-semibold "
                          >
                              Date
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Receipient's Name
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Account Number
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Amount
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Payment Memo
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Payment Tag
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                          Bank Name
                          </th>
                          <th scope="col" className="px-4 py-4 font-semibold">
                              Actions
                           </th> 
                        
                          </tr>
                      </thead>
                       <tbody>
                        {
                          BulkValidData.map((val, index)=>(
                            <tr
                            key={index}
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                            >
                              <th
                                  scope="row"
                                  className="py-4 px-4 font-normal text-[#5C715E]"
                              >
                                  <input 
                                  type="checkbox" 
                                  className="accent-primary"
                                  checked={isRowSelected(val)}
                                  onChange={() => handleCheckboxChange(val)}
                                    />
                               </th> 
                              <td
                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                              >
                             {moment(val?.date).format("lll")}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                              {val.recipient.name}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                              {val.account_number}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                              {formatToNaira(val.amount)}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                              {val.narration}
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                                <div className="flex">
                                  <div
                                    className='bg-[#DBFFDF] text-primary text-sm capitalize py-1 px-2 rounded-[4px] flex justify-center items-center font-medium'
                                  >
                                    <p>
                                      {val.recipient.tag}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="py-4 px-4 text-[#5C715E]">
                               {val.bank.name}
                              </td>
                              <td className="py-4  relative px-4 text-[#5C715E]">
                                  <div className="">
                                    <img
                                      src={more_icon}
                                      alt="more icon"
                                      onClick={() =>
                                        toggleDropdown(val.id)
                                      }
                                    />

                                    {openDropdownId === val.id && (
                                      <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                        <li
                                         onClick={() =>
                                          deleteRecipientInfo(val.id)
                                        }
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                          Remove Recipient
                                          </p>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </td>  
                        </tr>
                          ))
                        }
                        
                           
                      </tbody>        
                  </table>
                    }
                 

                 {
                   typeID === 2 && BulkInvalidData?.length === 0 
                    &&
                    <div className="w-full flex flex-col items-center justify-center min-h-[300px]">
                        <div className="text-center">
                          <div  className='flex justify-center'>
                            <img src={breaking_news_icon} alt="breaking_news_icon" />
                          </div>
                          <p className='text-sm text-center max-w-xs font-medium text-[#000000] mt-2'>
                        You don’t have any lists yet. When 
                        you create one, it will show up here.
                          </p>
                      </div>                           
                    </div>
                    }


                    {
                      typeID === 2 && BulkInvalidData?.length > 0 &&
                      <table className="w-full text-left relative mt-4">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                            <th
                                scope="col"
                                className="px-4 py-4 font-semibold "
                            >
                                Date
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Account Number
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Amount
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Payment Memo
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Payment Tag
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                            Bank Code
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                              Actions
                              </th> 
                            </tr>
                        </thead>
                         <tbody>
                          {
                            BulkInvalidData.map((val, index)=>(
                              <tr
                              key={val.id}
                              className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                              >
                              
                                <th
                                  className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                >
                                {moment(val?.date).format("lll")}
                                </th>
                                <td className="py-4 px-4 text-[#5C715E]">
                                {val.account_number}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                {formatToNaira(val.amount)}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                {val.narration}
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                  <div className="flex">
                                    <div
                                      className='bg-[#DBFFDF] text-primary text-sm capitalize py-1 px-2 rounded-[4px] flex justify-center items-center font-medium'
                                    >
                                      <p>
                                        {val.recipient_tag}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="py-4 px-4 text-[#5C715E]">
                                 {val.bank}
                                </td>  
                                <td className="py-4  relative px-4 text-[#5C715E]">
                                  <div className="">
                                    <img
                                      src={more_icon}
                                      alt="more icon"
                                      onClick={() =>
                                        toggleDropdown(val.id)
                                      }
                                    />

                                    {openDropdownId === val.id && (
                                      <ul className="absolute mt-5 bg-white z-[6] rounded-lg shadow-dropdown py-2 px-2 right-10 top-6 min-w-[187px]">
                                        <li
                                         onClick={() =>
                                          editRecipientInfo(val)
                                        }
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                          Edit Recipient
                                          </p>
                                        </li>
                                        <li
                                         onClick={() =>
                                          deleteRecipientInfo(val.id)
                                        }
                                          className="py-3 px-2 cursor-pointer hover:bg-[#EDF7EE]"
                                        >
                                          <p className="text-[#344335] text-sm font-medium">
                                          Remove Recipient
                                          </p>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </td>
                          </tr>
                            ))
                          }       
                        </tbody>        
                    </table>
                  }


              </>
               )
              }
              
        </div>
        }
        </>
     );
}
 
export default BulkPaymentPage;